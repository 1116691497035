.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  backdrop-filter: blur(50px);
  z-index: 10;
  div.earn_points {
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    background: url('../images/top-bar-bg.png');
    background-position: center;
    background-size: cover;
    img {
      height: 90%;
    }
    h2.get_now, p.earn_title {
      margin-bottom: 0;
    }
    h2.get_now {
      font-style: italic;
      color: #474d5d;
    }
    button.order_now {
      background-color: #ED1E79;
      outline: none;
      border: none;
      color: white;
      padding: 4px 24px;
      border-radius: 4px;
      font-weight: bold;
      cursor: pointer;
    }
  }
  nav.navigation {
    height: 6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    .logo {
      height: 4rem;
    }
    ul {
      display: flex;
      align-items: center;
      gap: 3rem;
      list-style: none;
      a {
        text-decoration: none;
        color: #ED1E79;
        font-size: 1.25em;
      }
      li.login {
        background-color: #ED1E79;
        padding: 0.5rem 1rem;
        border-radius: 50px;
        a {
          color: white;
        }
      }
    }
    div.hamburger {
      display: none;
    }
  }
  div.dropdown {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .header {
    nav.navigation {
      height: 4rem;
      .logo {
        height: 3rem;
      }
      ul {
        display: none;
      }
      div.hamburger {
        display: flex;
        flex-direction: column;
        row-gap: 0.25rem;
        cursor: pointer;
        div {
          height: 0.25rem;
          width: 2rem;
          border-radius: 4px;
          background-color: #ED1E79;
        }
      }
    }
    div.dropdown {
      border-top: 1px solid rgb(150, 150, 150);
      display: block;
      div.item {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid rgb(150, 150, 150);
        padding: 1rem 0;
        font-size: 1.1em;
        cursor: pointer;
        a {
          text-decoration: none;
          color: #ED1E79;
        }
      }
      div.login_btn {
        background-color: #ED1E79;
        a {
          color: white;
        }
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .header {
    div.earn_points {
      filter: grayscale(50%);
      img, h2.get_now, button {
        display: none;
      }
    }
  }
}