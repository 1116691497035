@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* main {
  min-height: 100vh;
  overflow: hidden;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.top_secret_container {
  position: fixed;
  left: 0;
  top: 40%;
  z-index: 50;
  text-align: center;
  color: white;
  line-height: 160%;
  pointer-events: none;
}

.card {
  width: 25rem;
  padding: 1rem;
  min-height: 7.5rem;
  white-space: nowrap;
  background-color: #f1589b;
  transform: translateX(-30rem);
  transition: all 1s ease-in-out;
}

.card svg {
  height: 4rem;
  position: absolute;
  right: -2rem;
  top: -2rem;
  cursor: pointer;
  pointer-events: all;
}

.open {
  transform: translateX(0rem);
}

.open + .top_secret_img {
  right: -5rem;
  left: unset;
}

.top_secret_img {
  height: 10rem;
  cursor: pointer;
  animation: shake 3s infinite;
  animation-delay: 4s;
  position: absolute;
  bottom: -8rem;
  left: 1rem;
  transition: all 1s ease-in-out;
  pointer-events: all;
}

@media screen and (max-width: 760px) {
  .top_secret_img {
    height: 7rem;
    top: unset;
    bottom: -5rem;
  }
  .card {
    width: 20rem;
  }
}

@keyframes shake {
  0% { transform: translateX(0); }
  2.5%, 7.5%, 12.5%, 17.5%, 22.5% { transform: translateX(-15px); }
  5%, 10%, 15%, 20% { transform: translateX(15px); }
  25% { transform: translateX(0); }
  100% { transform: translateX(0); }
}

.react-tabs__tab--selected{
  border-bottom: 2px solid #FF0070 !important;
}

.scrolled{
  position: sticky;
  left: 0;
  top: 20px;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open{
  background-color: rgba(0, 0, 0, 0.75) !important;
  z-index: 99;
}
.ReactModal__Content.ReactModal__Content--after-open{
  max-width: 1000px;
  width: auto;
  min-height: 200px;
  padding: 10px 20px;
  background: #FFFFFF;
  font-size: 13px;
  margin: 0 auto;
}
.modalhead h4{
  font-size: 16px;
  font-weight: bold;
}
.modalhead h5{
  font-size: 13px;
  font-weight: normal;
  margin: 0 0 35px 0;
}
.modalhead .closeicon{
  position: absolute;
  right: 20px;
  top: 20px;
  color: #00b279;
  font-size: 20px;
  cursor: pointer;
}
.modalbodycontent{
  margin-bottom: 10px;
}
.bodycontenttop{
  position: relative;
  margin-bottom: 20px;
}
.bodycontenttop span{
  font-size: 14px;
  font-weight: bold;
  display: block;
  position: absolute;
  left: 0;
  top: -13px;
  background: #fff;
  padding: 2px 5px;
}
.bodycontenttop i{
  display: block;
  border-bottom: 1px solid #ddd;
}
.bodycontentbody{
  margin-bottom: 30px;
}
.bodycontentbody ul{
  margin: 0;
  padding: 0;
}
.bodycontentbody li{
  display: inline-block;
  min-width: 30%;
  margin: 0 25px 0 0;
}
.bodycontentbody li:last-child{
  margin: 0;
}
.bodycontentbody li input{
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px 0 0;
  width: 15px;
  height: 15px;
}
.bodycontentbody li label{
  display: inline-block;
  vertical-align: middle;
  margin: 0;
}
.counterwrap button{
  background: #00b279;
  border: 1px solid #00b279;
  color: #fff;
  padding: 5px;
  outline: none;
  font-size: 28px;
  width: 40px;
  height: 40px;
  line-height: 1;
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;
}
.counterwrap span{
  border: 1px solid #ddd;
  padding: 5px;
  width: 100px;
  text-align: center;
  display: inline-block;
  margin: 0 20px;
  vertical-align: middle;
  height: 40px;
  line-height: 30px;
}
.szh-accordion__item-heading{
  margin: 0px;
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  font-size: 14px;
  cursor: pointer;
}
.szh-accordion__item-heading button{
  border: none;
  background: no-repeat;
  font-size: 14px;
  outline: none;
  cursor: pointer;
}
.szh-accordion__item-content{
  border: 1px solid #ddd;
  border-top: none;
}
.szh-accordion__item{
  margin-bottom: 20px;
}
.accrodiantable table{
  width: 100%;
}
.accrodiantable td{
  padding: 10px;
  border-bottom: 1px solid #ddd;
}
.accrodiantable td:last-child{
  text-align: center;
}
.accrodiantable td h6{
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}
.accrodiantable td input{
  display: inline-block;
  margin: 0 10px 0 0;
  vertical-align: middle;
}
.accrodiantable td label{
  margin: 0;
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
}
.modalfooter{
  text-align: right;
}
.modalfooter button{
  text-transform: uppercase;
  background: #EB1E78;
  border: 1px solid #FF0070;
  color: #fff;
  padding: 5px 8px;
  display: inline-block;
  margin-left: 20px;
  outline: none;
  min-width: 200px;
  cursor: pointer;
}
.modalfooter .closebtn{
  background: #00b279;
  border: 1px solid #00b279;
}
.accordion .accordion-header{
  cursor: pointer;
  position: relative;
}
.accordion .accordion-header h3{
  font-size: 14px;
  margin: 0;
}
.accordion .accordion-content > p{
  font-size: 13px;
  margin: 15px 0 10px 15px;
}
.expand{
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  display: none;
}
.expand span{
  font-size: 13px;
  color: #000;
}
.expand i{
  font-size: 12px;
  color: #FF0070;
}

@media (max-width: 640px) {
  .active .expand{
    display: none;
  }
  .active .expand.hide{
    display: block;
  }
  .inactive .expand{
    display: block;
  }
  .inactive .expand.hide{
    display: none;
  }
  .modalfooter{
    text-align: center;
  }
  .modalfooter button{
    margin-left: 10px;
    min-width: auto;
  }
}