div.main_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10rem;
  background-color: #F9F9F9;
  min-height: 100vh;
  .banner {
    height: 10rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url("../images/banner-2.jpg");
    h1, p {
      margin-bottom: 0;
    }
  }

  div.results {
    display: flex;
    gap: 2rem;
    width: 1200px;
    margin: 2rem 0;
    color: rgb(75, 75, 75);
    .right {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      .btns_container {
        display: flex;
        .filter_btn {
          display: none;
        }
        .view_btns {
          display: flex;
          gap: 1rem;
          button {
            border: none;
            outline: none;
            cursor: pointer;
            background-color: gray;
          }
          .active {
            background-color: #ED1E79;
          }
        }
      }
      .card {
        padding: 1rem;
        width: 100%;
        border: 1px solid rgb(199, 199, 199);
        background-color: white;
        display: flex;
        font-size: 0.9rem;
        .inner_left {
          display: flex;
          row-gap: 1rem;
          flex-direction: column;
          align-items: center;
          max-width: 140px;
          .features {
            display: flex;
            gap: 1rem;
            .feature {
              display: flex;
              gap: 4px;
              align-items: center;
              h6 {
                margin: 0;
              }
              svg {
                height: 0.85rem;
              }
            }
          }
          h6 {
            text-align: center;
          }
        }
        .inner_middle {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          margin: 0 2rem;
          .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .status {
              background-color: rgb(51, 212, 19);
              color: white;
              font-weight: bold;
              padding: 2px 4px;
              border-radius: 4px;
            }
          }
          h4.name {
            color: #ED1E79;
          }
          p.dishes {
            margin-bottom: 1rem;
            font-weight: bold;
          }
          p {
            margin: 0;
          }
        }
        .inner_right {
          width: 10rem;
          display: flex;
          flex-direction: column;
          row-gap: 1rem;
          justify-content: flex-end;
          button {
            background-color: #ED1E79;
            color: white;
            border: none;
            outline: none;
            padding: 0.5rem;
            width: 100%;
            font-size: 1rem;
          }
        }
      }
      .card_mobile {
        padding: 1rem;
        width: 100%;
        border: 1px solid rgb(199, 199, 199);
        background-color: white;
        display: none;
        gap: 1rem;
        font-size: 0.9rem;
        h4, p {
          padding: 0;
          margin: 0.5rem 0;
        }
        h4.name {
          color: #ED1E79;
        }
        p.status {
          background-color: rgb(51, 212, 19);
          color: white;
          font-weight: bold;
          padding: 2px 4px;
          border-radius: 4px;
          width: fit-content;
        }
        p.dishes {
          font-weight: bold;
        }
        .buttons {
          display: flex;
          gap: 1rem;
          margin-top: 1.5rem;
          button {
            background-color: #ED1E79;
            color: white;
            border: none;
            outline: none;
            padding: 0.5rem;
            width: fit-content;
            font-size: 1rem;
          }
        }
      }
      .grid_container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 1.5rem;
        column-gap: 1.5rem;
        .grid_card {
          border: 1px solid rgb(199, 199, 199);
          padding: 1rem 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          p {
            margin: 0.4rem 1rem;
          }
          h1 {
            color: #ED1E79;
            font-weight: normal;
            margin: 1rem;
          }
          .review_min_order {
            display: flex;
            width: 100%;
            border-top: 1px solid rgb(199, 199, 199);
            border-bottom: 1px solid rgb(199, 199, 199);
            margin: 1.5rem 0;
            div {
              width: 50%;
            }
            div:not(:last-child) {
              border-right: 1px solid rgb(199, 199, 199);
            }
          }
          p.status {
            background-color: rgb(51, 212, 19);
            color: white;
            font-weight: bold;
            padding: 2px 4px;
            border-radius: 4px;
            width: fit-content;
            margin-bottom: 1.5rem;
          }
          p.dishes {
            font-weight: bold;
          }
          .buttons {
            display: flex;
            flex-direction: column;
            row-gap: 1rem;
            margin-top: 1.5rem;
            button {
              background-color: #ED1E79;
              color: white;
              border: none;
              outline: none;
              padding: 1rem 1.5rem;
              border-radius: 4px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1260px) {
  div.main_container {
    div.tabs {
      padding: 0 2rem;
    }
    div.results {
      width: 100%;
      padding: 0 2rem;
      .left {
        display: none;
      }
      .right {
        .btns_container {
          .filter_btn {
            display: initial;
          }
          .view_btns {
            display: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  div.main_container {
    div.results {
      .right {
        .card {
          display: none;
        }
        .card_mobile {
          display: flex;
        }
      }
    }
  }
}

@media screen and (max-width: 760px) {
  div.main_container {
    div.results {
      .right {
        .grid_container {
          grid-template-columns: 1fr;
        }
      }
    }
  }
}

@media screen and (max-width: 660px) {
  div.main_container {
    div.tabs {
      display: none;
    }
  }
}

@media screen and (max-width: 660px) {
  div.main_container {
    div.results {
      .right {
        .card_mobile {
          display: flex;
          img {
            height: 120px;
            width: 120px;
          }
          .buttons {
            flex-direction: column;
            button {
              width: 100%;
            }
          }
        }
      }
    }
  }
}