div.main_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10rem;
  background-color: #F9F9F9;

  .banner {
    height: 10rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url("../images/banner-2.jpg");
    h1 {
      margin-bottom: 0;
    }
    p {
      margin-top: 10px;
      font-weight: bold;
    }
  }

  div.tabs {
    background-color: #1a2533;
    color: white;
    display: flex;
    justify-content: center;
    height: 2.6rem;
    width: 100%;
    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // width: 900px;
      margin: 0;
      padding: 0;
      list-style: none;
      .browse {
        color: #ED1E79;
        font-weight: bold;
      }
      li:hover {
        color: #ED1E79;
        cursor: pointer;
      }
    }
  }

  .login_form_container {
    margin-top: 20px;
    margin-bottom: 50px;
    width: 100%;
    .container {
      display: flex;
      justify-content: center;
      .login_form {
        background: #FFFFFF;
        padding: 17px;
        border: 1px solid #c9c7c7;
        .login_form_header {
          display: flex;
          justify-content: center;
          align-items: center;
          label {
            margin: 0;
          }
          hr {
            width: 50%;
          }
        }
        .login_form_content {
          margin-top: 20px;
        }
        .login_form_footer {
          display: flex;
          justify-content: space-between;
          label {
            cursor: pointer;
            color: #FF0070;
          }
        }
      }
    }
  }
}