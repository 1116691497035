.footer {
  padding: 3rem 6rem 1rem 6rem;
  border-top: 1px solid rgb(220, 220, 220);
  .links {
    border-bottom: 1px solid rgb(220, 220, 220);
    padding-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    h3 {
      font-weight: normal;
      color: #ED1E79;
    }
    h4 {
      font-weight: 400;
      font-size: 0.9rem;
      color: gray;
      margin: 1rem 0;
    }
    section {
      width: 33%;
    }
    section.contact {
      div.icon_with_name {
        display: flex;
        align-items: center;
        margin: 1rem 0;
        gap: 1rem;
        svg {
          height: 1rem;
        }
        h4 {
          margin: 0;
        }
      }
    }
    section.subscribe {
      .send_email_container {
        display: flex;
        align-items: center;
        width: 100%;
        border: 1px solid rgb(228, 228, 228);
        background: rgb(244, 244, 244);
        padding: 0.5rem 1rem;
        border-radius: 50px;
        font-size: 0.8em;
        box-shadow: 5px 5px 10px rgb(217, 217, 217);
        input {
          flex-grow: 1;
          outline: none;
          border: none;
          background: transparent;
        }
        svg {
          height: 1rem;
        }
      }
    }
  }
  div.trademark {
    padding-top: 1rem;
    font-size: 0.8em;
  }
}

@media screen and (max-width: 1024px) {
  .footer {
    .links {
      flex-wrap: wrap;
      section {
        width: 100%;
      }
      section.subscribe, section.contact {
        border-top: 1px solid rgb(220, 220, 220);
        padding-top: 3rem;
      }
    }
  }
}

@media screen and (max-width: 560px) {
  .footer {
    padding: 3rem 3rem 1rem 3rem;
  }
}