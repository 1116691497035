


div.main_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10rem;
  background-color: #F9F9F9;
  min-height: 100vh;

  .banner {
    height: 10rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url("../images/banner-2.jpg");

    h1,
    p {
      margin-bottom: 0;
    }

    p {
      margin-top: 20px;
      font-weight: bold;
    }
  }

  div.results {
    gap: 2rem;
    width: 1200px;
    margin: 2rem 0;
    color: rgb(75, 75, 75);

    .result_content {
      max-width: 550px;
      margin: auto;

      .header {
        width: 100%;
        background: #FF0070;
        color: #fff;
        font-family: "Lato", sans-serif;
        font-size: 16px;
        font-weight: bold;
        margin: 0;
        padding: 8px 20px;

        label {
          margin-bottom: 0px;
        }
      }

      .box_gray {
        background: #FFFFFF;
        border: 1px solid #c9c7c7;
        padding: 15px 20px;

        .item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;
        }

        .item_gray {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #f9f9f9;
          font-size: 14px;
        }
      }

      .item_title {
        font-size: 16px;
        font-weight: bold;
      }

      .item {
        display: flex;
        justify-content: space-between;

        .action {

          .enidIcon,
          .delIcon {
            margin-right: 10px;

            &:hover {
              color: red;
              cursor: pointer;
            }
          }

        }
      }

      .sub_item {
        margin-top: 3px;

        .sub_title {
          font-size: 14px;
        }

        .sub_price {
          display: flex;
          justify-content: space-between;
          font-size: 14px;
        }

        label {
          margin-bottom: 0px;
        }
      }

      .price_container {
        display: flex;
        justify-content: space-between;

        .sub_total {
          font-size: 16px;
          margin-bottom: 0px;
        }

        .total {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 0px;

        }
      }
    
      .footer {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .print {
          cursor: pointer;
          &:hover {
            color: #FF0070;
          }
        }
      }

    }

  }
}


@media only screen and (max-width: 767px) {
  div.results {
    .result_content {
      max-width: 360px !important;
    }
  }
}