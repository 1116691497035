.toptabs {
  width: 100%;

  .tabs {
    background: #1a2533;
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 1px solid #060606;
    border-top: 1px solid #060606;
    text-align: center;
    color: white;
    display: flex;
    justify-content: center;
    width: 100%;

    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 1200px;
      margin: 0;
      padding: 0;
      list-style: none;

      .browse {
        color: #ED1E79;
        font-weight: bold;
      }

      li:hover {
        color: #ED1E79;
        cursor: pointer;
      }
    }
  }

  .mobtabs {
    background: #1a2533;
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 1px solid #060606;
    text-align: center;
    color: white;
    display: flex;
    justify-content: center;
    width: 100%;
    background: #fff;
    border-bottom-color: #ccc;
    display: none;

    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 200px;
      margin: 0 auto;
      padding: 0;
      list-style: none;

      li {
        span {
          width: 20px;
          height: 20px;
          background: #ddd;
          display: block;
          border-radius: 50%;
        }
      }

      li.browse {
        span {
          background: #ED1E79;
        }
      }
    }
  }
}

@media (max-width: 640px) {
  .toptabs {
    width: 100%;

    .tabs {
      display: none;
    }

    .mobtabs {
      display: block;
    }
  }
}