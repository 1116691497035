.card_container {
  width: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center !important;
  .card {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 500px;
    height: 500px;
    background-image: url('../../assets/images/customer-bubble.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    p {
      width: 50%;
      text-align: center;
      font-size: 0.9em;
      margin-left: 4rem;
    }
  }
}

@media screen and (max-width: 620px) {
  .card_container {
    .card {
      height: 350px;
      width: 350px;
      p {
        font-size: 0.7em;
      }
    }
  }
}