div.main_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10rem;
  background-color: #F9F9F9;
  .banner {
    height: 10rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url("../images/banner-2.jpg");
    h1 {
      margin-bottom: 0;
    }
    p {
      margin-top: 10px;
      font-weight: bold;
    }
  }

}