.card_container {
  padding: 1rem;
  margin-right: 4rem;
  .card {
    height: 18rem;
    box-shadow: 0 0 5px rgba(128, 128, 128, 0.61);
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    img.banner {
      width: 100%;
      height: 50%;
      object-fit: cover;
    }
    img.logo {
      position: absolute;
      top: calc(50% - 3rem);
      left: calc(50% - 3rem);
      transform-origin: center;
      height: 6rem;
      width: 6rem;
      border-radius: 6rem;
    }
    button.pre_order_btn {
      position: absolute;
      right: 1rem;
      top: calc(50% + 1rem);
      background-color: #5bc0de;
      color: white;
      outline: none;
      border: none;
      padding: 4px 8px;
      border-radius: 4px;
    }
    .description {
      margin: 4rem 1rem 1rem 1rem;
      .header {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .card_container {
    padding: 0.4rem;
    .card {
      height: 18rem;
    }
  }
}