.wrapper {
  border: 1px solid rgb(199, 199, 199);
  border-radius: 8px;
  padding: 1rem;
  width: 18rem;
  background-color: white;
  h3 {
    margin: 0;
    margin-bottom: 2rem;
  }
  section {
    margin-bottom: 2rem;
    .title {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-bottom: 0.5rem;
      p {
        margin: 0;
      }
      div {
        flex-grow: 1;
        height: 1px;
        background-color: rgb(201, 201, 201);
      }
    }
    input.searchbar {
      border: 1px solid rgb(185, 185, 185);
      outline: none;
      padding: 0.5rem;
      border-radius: 50px;
      width: 100%;
    }
  }
  .checkbox_container, .radio_container {
    display: flex;
    flex-direction: column;
  }
}