div.main_container {
  .hero {
    height: 1026px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #F9F9F9;
    overflow: hidden;
    .bg_img {
      height: 500px;
      width: 500px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      z-index: 0;
    }
    .bg_img_one {
      background-image: url("../images/bg-desk-top-right.png");
      top: -100px;
      right: -100px;
      width: 400px;
    }
    .bg_img_two {
      background-image: url("../images/bg-desk-bottom-right.png");
      bottom: 100px;
      right: -130px;
    }
    .bg_img_three {
      background-image: url("../images/bg-desk-left.png");
      left: -100px;
      bottom: -10px;
    }
    h1 {
      font-size: 2.5rem;
      margin-top: 16rem;
    }
    p {
      font-weight: 500;
      margin-bottom: 3rem;
    }
    .cards {
      display: flex;
      gap: 1rem;
      .card_one, .card_two {
        height: 9rem;
        width: 8rem;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0.5rem;
        background-color: white;
      }
      .card_one {
        border: 6px solid #ED1E79;
      }
      .card_two {
        position: relative;
        overflow: hidden;
      }
      .card_two::after {
        content: "Coming Soon";
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        color: white;
        background-color: rgba(0, 0, 0, 0.7);
      }
    }
    .searchbar {
      display: flex;
      align-items: center;
      gap: 12px;
      border: 4px solid rgb(207, 207, 207);
      box-shadow: 5px 5px 10px rgb(223, 223, 223);
      border-radius: 50px;
      margin-top: 4rem;
      background-color: white;
      input {
        outline: none;
        border: none;
        flex-grow: 1;
        width: 350px;
        margin-left: 1rem;
      }
      .close_btn {
        height: 1rem;
        width: 1rem;
        cursor: pointer;
      }
      .search_btn {
        padding: 0.5rem 1rem;
        border-radius: 50px;
        background-color: #ED1E79;
        color: white;
        font-size: 1.5rem;
        border: none;
        outline: none;
        cursor: pointer;
      }
    }
  }
  .how_it_works {
    padding: 4rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      color: #ED1E79;
    }
    .img_container {
      max-width: 1200px;
      h1 {
        color: rgb(62, 62, 62);
      }
      p {
        color: gray;
      }
      img {
        object-fit: contain;
        width: 100%;
      }
      .content {
        display: flex;
        justify-content: space-between;
        padding-left: 6%;
        .lower, .upper {
          position: relative;
          text-align: center;
        }
        .lower {
          top: 1rem;
        }
        .upper {
          top: -6rem;
        }
      }
    }
    .mobile_view {
      h1 {
        color: rgb(62, 62, 62);
      }
      p {
        color: gray;
      }
      display: none;
    }
  }
  .featured {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4rem;
    background-color: #F9F9F9;
    > h1 {
      color: #ED1E79;
      margin-bottom: 3rem;
    }
    .slider {
      max-width: 1200px;
    }
  }
  .reviews {
    display: flex;
    justify-content: center;
    position: relative;
    padding: 5rem 0;
    .top_right {
      position: absolute;
      top: 0;
      right: -50px;
      height: 200px;
      width: 200px;
      background: url("../images/customers-top-right.png");
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 2;
    }
    .bottom_left {
      position: absolute;
      bottom: 0;
      left: 0px;
      height: 400px;
      width: 250px;
      background: url("../images/customers-bg-desk.png");
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 2;
    }
    .title {
      position: absolute;
      right: 150px;
      top: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;
      h1 {
        text-align: center;
        font-size: 2rem;
        line-height: 140%;
        color: #ED1E79;
      }
      .arrows {
        display: flex;
        gap: 1rem;
        img {
          cursor: pointer;
        }
      }
    }
    .slider {
      max-width: 100vw;
    }
  }
  .app {
    height: 500px;
    background: url('../images/app-bg-desktop.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 160px;
    .buttons {
      display: flex;
       margin-top: 1rem;
      gap: 1rem;
      button {
        background-color: #ED1E79;
        color: white;
        border-radius: 50px;
        padding: 0.5rem 2rem;
        display: flex;
        gap: 1rem;
        cursor: pointer;
        svg {
          height: 2rem;
        }
      }
    }
  }
}

@media screen and (max-width: 1260px) {
  div.main_container {
    .how_it_works {
      .img_container {
        max-width: 1000px;
      }
    }
    .featured {
      .slider {
        max-width: 90vw;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  div.main_container {
    .hero {
      .bg_img {
        height: 350px;
        width: 350px;
      }
      .bg_img_one {
        top: 0;
      }
      .bg_img_two {
        bottom: 0;
      }
      h1 {
        font-size: 1.8rem;
        text-align: center;
      }
      .searchbar {
        input {
          width: 200px;
        }
        .search_btn {
          font-size: 1rem;
        }
      }
    }
    .how_it_works {
      .img_container {
       display: none;
      }
      .mobile_view {
        display: block;
        margin: 4rem;
        .content {
          display: flex;
          gap: 2rem;
          text-align: center;
          img {
            margin-bottom: 1rem;
          }
        }
      }
    }
    .reviews {
      .title {
        right: 50px;
        z-index: 5;
      }
    }
  }
}

@media screen and (max-width: 870px) {
  .how_it_works {
    .img_container {
     display: none;
    }
    .mobile_view {
      margin: 2rem;
      .content {
        display: flex;
        flex-direction: column;
        p {
          font-size: 1.25em;
        }
        div:not(:last-child) {
          margin-bottom: 3rem;
        }
      }
    }
  }
}

@media screen and (max-width: 760px) {
  div.main_container {
    .hero {
      .bg_img {
        height: 250px;
        width: 250px;
      }
      .bg_img_three {
        bottom: 100px;
      }
      .bg_img_two {
        bottom: -50px;
        right: -100px;
      }
      h1 {
        font-size: 1.8rem;
        text-align: center;
      }
      .searchbar {
        input {
          width: 160px;
        }
        .search_btn {
          font-size: 1rem;
        }
      }
    }
    .reviews {
      .top_right {
        height: 100px;
        width: 100px;
      }
      .bottom_left {
        height: 200px;
        width: 100px;
      }
      .title {
        display: none;
      }
    }
    .app {
      padding: 2rem;
      background: url('../images/mobile-bg.png');
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      .buttons {
        button {
          padding: 0.5rem 1rem;
          font-size: 0.8rem;
        }
      }
    }
  }
}