.innerbanner{
    background: url(../images/banner-2.jpg) no-repeat;
    background-size: cover;
    padding: 50px 0 15px;
    margin-top: 140px;
    .container{
        max-width: 1200px;
        margin: 0 auto;
        .bannercontent{
            text-align: center;
            .bannerlogo{
                margin-bottom: 10px;
                span{
                    background: url(../images/default-image-merchant.png) no-repeat;
                    background-size: 100% auto;
                    width: 75px;
                    height: 100px;
                    display: block;
                    margin: 0 auto;
                }
            }
            .ratingetcsec{
                margin-left: 30px;
                ul{
                    padding: 0;
                    margin: 0;
                    li{
                        margin: 0 25px 0 0;
                        padding: 0;
                        display: inline-block;
                        vertical-align: middle;
                        ol{
                            padding: 0;
                            margin: 0;
                            li{
                                margin: 0 2px 0 0;
                                padding: 0;
                                display: inline-block;
                                vertical-align: middle;
                            }
                        }
                        .reviewbtn{
                            a{
                                text-align: center;
                                color: #080808;
                                margin-bottom: 20px;
                                font-weight: bold;
                                font-size: 13px;
                                text-decoration: none;
                            }
                        }
                        .shopstatus{
                            span{
                                background: #5cb85c;
                                padding: 4px 5px;
                                color: #fff;
                                text-align: center;
                                border-radius: 0.25em;
                                font-size: 12px;
                                font-weight: bold;
                            }
                        }
                        .minorder{
                            span{
                                color: #080808;
                                font-weight: bold;
                                font-size: 13px;
                                em{
                                    font-style: normal;
                                }
                            }
                        }
                        .addfav{
                            a{
                                color: #fff;
                                font-size: 24px;
                            }
                        }
                    }
                }
            }
            h1{
                color: #000000;
                font-size: 40px;
                text-align: center;
                font-weight: bold;
                font-family: Roboto, sans-serif;
                margin: 5px 0;
            }
            p{
                text-align: center;
                color: #080808;
                margin-bottom: 5px;
                font-weight: 500;
                font-size: 16px;
            }
        }
    }
}
.toptabs{
    .tabs {
        background: #1a2533;
        padding-bottom: 10px;
        padding-top: 10px;
        border-bottom: 1px solid #060606;
        border-top: 1px solid #060606;
        text-align: center;
        color: white;
        display: flex;
        justify-content: center;
        width: 100%;
        ul {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 1200px;
          margin: 0;
          padding: 0;
          list-style: none;
          .browse {
            color: #ED1E79;
            font-weight: bold;
          }
          li:hover {
            color: #ED1E79;
            cursor: pointer;
          }
        }
      }
    .mobtabs{
        background: #1a2533;
        padding-bottom: 10px;
        padding-top: 10px;
        border-bottom: 1px solid #060606;
        text-align: center;
        color: white;
        display: flex;
        justify-content: center;
        width: 100%;
        background: #fff;
        border-bottom-color: #ccc;
        display: none;
        ul{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 200px;
            margin: 0 auto;
            padding: 0;
            list-style: none;
            li{
                span{
                    width: 20px;
                    height: 20px;
                    background: #ddd;
                    display: block;
                    border-radius: 50%;
                }
            }
            li.browse {
                span{
                    background: #ED1E79;
                }
            }
        } 
    }
}
.middlesection{
    background: #eff2f5;
    padding: 10px 0 30px 0;
    .container{
        max-width: 1200px;
        margin: 0 auto;
        .flexgrid{
            display: flex;
            justify-content: space-between;
            .col8{
                width: 66%;
                .tabsbox{
                    border: 1px solid #c9c7c7;
                    padding: 0;
                    .reactlist{
                        margin: 0;
                        list-style-type: none;
                        padding: 0;
                        text-align: center;
                        background: #fff;
                        border-bottom: 1px solid #c9c7c7;
                        .listtabs{
                            display: inline-block;
                            padding: 5px 15px;
                            margin-bottom: 4px;
                            cursor: pointer;
                            font-size: 14px;
                            color: #474d5d;
                            font-weight: 400;
                            border-bottom: 2px transparent solid;
                            margin-right: 10px;
                            outline: none;
                            &:hover{
                                border-bottom: 2px solid #FF0070;
                            }
                            i{
                                font-size: 18px;
                                color: #828385;
                                display: none;
                            }
                        }
                    }
                    .tabspanelwrap{
                        padding: 10px 8px;
                        .panelcontent{
                            background: #FFFFFF;
                            border: 1px solid #c9c7c7;
                            padding: 15px 20px;
                            margin-bottom: 15px;
                            h2{
                                font-size: 24px;
                            }
                            .mapcontent{
                                .mapwrap{
                                    margin-bottom: 10px;
                                    iframe{
                                        border: 1px solid #c9c7c7;
                                        border-top: 2px solid #00B279;
                                        max-width: 100%;
                                        min-height: 400px;
                                        max-height: 400px;
                                        width: 100%;
                                    }
                                }
                                input{
                                    border: 1px solid #ddd;
                                    padding: 5px;
                                    width: 300px;
                                    margin-right: 15px;
                                    height: 36px;
                                    outline: none;
                                }
                                select{
                                    border: 1px solid #ddd;
                                    padding: 5px;
                                    width: 200px;
                                    margin-right: 20px;
                                    height: 36px;
                                    outline: none;
                                }
                                button{
                                    background: #00b279;
                                    border: 1px solid #00b279;
                                    color: #fff;
                                    padding: 5px 10px 7;
                                    height: 36px;
                                    border-radius: 2px;
                                    font-size: 14px;
                                    font-weight: 500;
                                    outline: none;
                                    &:hover{
                                        text-decoration: none;
                                        color: #fff;
                                        background: #23c562;
                                        border: 1px solid #23c562;
                                    }
                                }
                            }
                            .reviewboxwrap{
                                .reviewbox{
                                    display: flex;
                                    justify-content: space-between;
                                    border-top: 1px solid #c9c7c7;
                                    padding: 10px;
                                    margin-bottom: 10px;
                                    .reviewimg{
                                        margin-right: 20px;
                                        width: 60px;
                                        img{
                                            width: 100%;
                                            height: auto;
                                            display: block;
                                        }
                                    }
                                    .review{
                                        width: 85%;
                                        .ratingyears{
                                            margin-bottom: 10px;
                                            .rating{
                                                padding: 0;
                                                margin: 0 30px 0 0;
                                                display: inline-block;
                                                vertical-align: middle;
                                                li{
                                                    display: inline-block;
                                                    margin: 0 2px;
                                                    vertical-align: middle;
                                                    font-size: 16px;
                                                    color: #ffc240;
                                                }
                                            }
                                            span{
                                                display: inline-block;
                                                vertical-align: middle;
                                                font-size: 12px;
                                            }
                                        }
                                        p{
                                            margin: 0;
                                            font-size: 14px;
                                        }
                                    }
                                }
                            }
                            .hours{
                                ul{
                                    padding: 0;
                                    margin: 0;
                                    li{
                                        display: flex;
                                        border-top: 1px solid #c9c7c7;
                                        padding: 7px;
                                        justify-content: space-between;
                                        .day{
                                            width: 150px;
                                            i{
                                                display: inline-block;
                                                vertical-align: middle;
                                                font-size: 10px;
                                                margin-right: 3px;
                                                color: #00B279;
                                            }
                                            span{
                                                font-size: 14px;
                                                font-weight: 400;
                                                color: #474d5d;
                                            }
                                        }
                                        .time{
                                            width: 75%;
                                            span{
                                                font-size: 14px;
                                                font-weight: 400;
                                                color: #474d5d;
                                            }
                                        }
                                    }
                                }
                            }
                            .menuwrap{
                                display: flex;
                                justify-content: space-between;
                                .menulist{
                                    width: 200px;
                                    position: relative;
                                    ul{
                                        padding: 0;
                                        margin: 0;
                                        li{
                                            display: block;
                                            a{
                                                display: block;
                                                border: 1px solid #c9c7c7;
                                                border-bottom: 0;
                                                color: #030303;
                                                padding: 10px 8px;
                                                font-size: 14px;
                                                background: #fff;
                                                border-left: 2px solid #c9c7c7;
                                                position: relative;
                                                &:last-child{
                                                    border-bottom: 1px solid #c9c7c7;
                                                }
                                                span{
                                                    color: #999;
                                                    font-size: 11px;
                                                }
                                                &:hover{
                                                    border-left: 2px solid #FF0070;
                                                    background: #f8f8f8;
                                                    text-decoration: none;
                                                }
                                                i{
                                                    position: absolute;
                                                    right: 5px;
                                                    top: 50%;
                                                    transform: translate(0, -50%);
                                                    font-size: 11px;
                                                    color: #FF0070;
                                                }
                                            }
                                        }
                                    }
                                }
                                .menucontent{
                                    width: 70%;
                                    > a{
                                        font-size: 15px;
                                        color: #00B279;
                                        font-weight: normal;
                                        text-align: center;
                                        display: block;
                                        margin-bottom: 10px;
                                    }
                                    .menuboxwrap{
                                        background: #FFFFFF;
                                        border: 1px solid #c9c7c7;
                                        padding: 15px 20px;
                                        border-top: 2px solid #00B279;
                                        margin-bottom: 25px;
                                        .menubox{
                                            .menuhead{
                                                background: #f8f8f8;
                                                border-bottom: 1px solid #c9c7c7;
                                                padding: 10px;
                                                margin-top: 15px;
                                                h5{
                                                    font-size: 14px;
                                                    margin: 0;
                                                }
                                                span{
                                                    font-size: 14px;
                                                }
                                            }
                                            ul{
                                                padding: 0;
                                                margin: 0;
                                                li{
                                                    display: flex;
                                                    justify-content: space-between;
                                                    font-size: 14px;
                                                    padding: 10px;
                                                    .size{
                                                        width: 60%;
                                                        text-align: left;
                                                        span{
                                                            margin-top: 5px;
                                                            display: block;
                                                        }
                                                    }
                                                    .price{
                                                        text-align: center;
                                                        width: 30%;
                                                        span{
                                                            margin-top: 5px;
                                                            display: block;
                                                        }
                                                    }
                                                    .addcartbtn{
                                                        width: 20%;
                                                        span{
                                                            width: 30px;
                                                            height: 30px;
                                                            display: block;
                                                            border-radius: 50%;
                                                            text-align: center;
                                                            line-height: 30px;
                                                            border: 1px solid #00B279;
                                                            color: #00B279;
                                                            margin: 0 auto;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .col4{
                width: 33%;
                .delinfobox{
                    background: #FFFFFF;
                    border: 1px solid #c9c7c7;
                    padding: 0;
                    .innerbox,
                    .boxborder{
                        padding: 8px 20px;
                        text-align: center;
                        h2{
                            font-size: 16px;
                            margin-bottom: 2px;
                        }
                        p{
                            font-size: 14px;
                            margin-bottom: 0;
                            padding: 0;
                        }
                        a{
                            font-size: 14px;
                            color: #00B279;
                            font-weight: normal;
                        }
                        .modinforul{
                            display: none;
                            padding: 0;
                            margin: 0;
                            li{
                                display: inline-block;
                                margin-right: 15px;
                                font-size: 11px;
                                span{
                                    display: inline-block;
                                    margin-left: 5px;
                                }
                            }
                        }
                    }
                    .boxborder{
                        padding: 8px 20px;
                        text-align: center;
                        border-top: 1px solid #c9c7c7;
                        position: relative;
                        h2{
                            margin-bottom: 10px;
                        }
                        .boxicon{
                            background: url(../images/your-order.png) no-repeat;
                            background-size: 100% auto;
                            width: 35px;
                            height: 35px;
                            position: absolute;
                            left: 10px;
                            top: 10px;
                        }
                        .boxicon2{
                            background: url(../images/carticon.jpg) no-repeat;
                            background-size: 100% auto;
                            width: 30px;
                            height: 35px;
                            position: absolute;
                            left: 10px;
                            top: 10px;
                        }
                        .radiowrap{
                            text-align: left;
                            padding-left: 30px;
                            .radio{
                                margin-bottom: 5px;
                                input{
                                    display: inline-block;
                                    margin: 0 5px;
                                    vertical-align: middle;
                                    width: 18px;
                                    height: 18px;
                                }
                                label{
                                    display: inline-block;
                                    vertical-align: middle;
                                    margin: 0;
                                    color: #00B279;
                                    font-size: 16px;
                                }
                            }
                        }
                        .checkboxwrap{
                            margin: 15px 0;
                            input{
                                display: inline-block;
                                margin: 0 5px;
                                vertical-align: middle;
                                width: 18px;
                                height: 18px;
                            }
                            label{
                                display: inline-block;
                                vertical-align: middle;
                                margin: 0;
                                color: #777;
                                font-size: 16px;
                            }
                        }
                        button{
                            border-radius: 0;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 640px) {
    .innerbanner{
        padding: 25px 0 15px;
        margin-top: 110px;
        .container{
            .bannercontent{
                .bannerlogo{
                    margin: 0;
                    span{
                        width: 60px;
                        height: 80px;
                    }
                }
                .ratingetcsec{
                    margin-left: 0;
                    ul{
                       > li{
                            display: block;
                            margin: 0;
                            padding: 0;
                        }
                    }
                }
                h1{
                    color: #ED1E79;
                    font-size: 22px;
                    margin: 0 5px 5px;
                }
                p{
                    font-weight: 400;
                    font-size: 14px;
                }
            }
        }
        
    }
    .toptabs{
        .tabs{
            display: none;
        }
        .mobtabs{
            display: block;
        }
    }
    .middlesection{
        padding: 10px;
        .container{
            .flexgrid{
                flex-direction: column-reverse;
                .col8{
                    width: 100%;
                    .tabsbox{
                        .reactlist{
                            .listtabs{
                                span{
                                    display: none;
                                }
                                i{
                                    display: block;
                                }
                            }
                        }
                        .tabspanelwrap{
                            .panelcontent{
                                .menuwrap{
                                    .menulist{
                                        display: none;
                                    }
                                    .menucontent{
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
                .col4{
                    width: 100%;
                    margin-bottom: 15px;
                    .delinfobox{
                        background: none;
                        border: none;
                        .boxborder{
                            display: none;
                        }
                        .innerbox{
                            padding: 8px 10px;
                            p{
                                display: none;
                            }
                            a{
                                display: none;
                            }
                            .modinforul{
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}
