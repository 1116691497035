div.main_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10rem;
  background-color: #F9F9F9;
  min-height: 100vh;

  .banner {
    height: 10rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url("../images/banner-2.jpg");

    h1,
    p {
      margin-bottom: 0;
    }

    p {
      margin-top: 20px;
      font-weight: bold;
    }
  }

  div.results {
    gap: 2rem;
    width: 1200px;
    margin: 2rem 0;
    color: rgb(75, 75, 75);

    .result_content {
      .result_left {
        width: 100%;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
        background: #FFFFFF;
        border: 1px solid #c9c7c7;
        padding: 15px 20px;
        margin-top: 15px;

        .description {
          font-size: 14px;
        }

        .phone {
          width: 225px;
        }

        .payinfo {
          margin-top: 10px;
          display: flex;

          hr {
            width: 60%;
          }
        }

        .radiowrap {
          text-align: left;

          .radio {
            margin-bottom: 5px;
            display: flex;
            input {
              display: inline-block;
              margin: 0 5px;
              vertical-align: middle;
              width: 18px;
              height: 18px;
            }

            label {
              font-size: 14px;
            }
          }
        }
      }

      .result_right {
        width: 100%;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
        background: #FFFFFF;
        border: 1px solid #c9c7c7;
        margin-top: 15px;

        .header {
          background-color: #EB1E78;
          height: 80px;
          align-items: center;
          display: flex;
          justify-content: center;

          p {
            margin-bottom: 0;
            font-weight: bold;
            color: white;
          }
        }

        .reviewBtn {
          text-align: center;
          margin-bottom: 20px;
          margin-top: 20px;
        }

      }
    }

  }
}

@media (max-width: 640px) {
  div.results {
    width: 100% !important;
    padding: 10px;
  }
}