.item_container {
  .item_content {
    height: 500px;
    overflow-y: scroll;
    padding: 10px;
    .item_title {
      font-size: 16px;
      font-weight: bold;
    }
    .item{  
      display: flex;
      justify-content: space-between;
      .action {
        .enidIcon, .delIcon {
          margin-right: 10px;
          &:hover {
            color: red;
            cursor: pointer;
          }
        }
        
      }
    }
    .sub_item {
      margin-top: 3px;
      .sub_title {
        font-size: 14px;
      }
      .sub_price {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
      }
      label {
        margin-bottom: 0px;
      }
    }
    .price_container {
      display: flex;
      justify-content: space-between;
      .sub_total {
        font-size: 16px;
        margin-bottom: 0px;
      }
      .total {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 0px;
  
      }
    }
    
    .suchform{
      border: 1px solid #555;
      border-radius: 4px;
      padding: 5px;
      margin-top: 5px;
      label {
        font-size: 14px;
      }
    }

    .points_text {
      font-size: 14px;
      font-weight: bold;
      margin-top: 5px;
      margin-bottom: 0px;
    }
    

  }
}